<template>
  <div id="login_page" class="login-page">
    <div class="login-left">
      <div class="title">
        <p>湖北省公路特大和特殊结构</p>
        <p>桥梁健康监测系统</p>
<!--        <p>十堰至淅川高速公路(湖北段)</p>-->
<!--        <p>丹江口水库特大桥结构健康监测系统</p>-->
<!--        <p>武汉交投高速公路</p>-->
<!--        <p>桥梁结构健康监测系统</p>-->
<!--        <p>武深高速陆水河特大桥</p>-->
<!--        <p>桥梁健康监测系统</p>-->
      </div>
    </div>
    <div class="login-container" id="login_box">
      <div class="login-title">
        <p>用户登录</p>
        <p>欢迎来到湖北省公路特大结构桥梁健康监测系统</p>
<!--        <p>欢迎来到丹江口水库特大桥结构健康监测系统</p>-->
<!--        <p>欢迎来到武汉交投高速公路桥梁结构健康监测系统</p>-->
<!--        <p>欢迎来到武深高速陆水河特大桥结构健康监测系统</p>-->
      </div>
      <div class="login-content">
        <el-form :model="data" :rules="rules" ref="data" label-position="left">
          <el-form-item prop="username" label="用户名">
            <el-input
              v-model="data.username"
              type="text"
              placeholder="请输入用户名"
              @keyup.enter.native="submitForm()"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input
              v-model="data.password"
              type="password"
              placeholder="请输入密码"
              show-password
              @keyup.enter.native="submitForm()"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            :disabled="isSubmit"
            class="btn btn-success btn-block"
            @click="submitForm()"
            >登录</el-button
          >
        </el-form>
      </div>
      <div v-if="sysMsg" class="err-msg">{{ sysMsg }}</div>
    </div>
  </div>
</template>

<script>
import { hex_sha1 } from "@/utils/sha1.js";
export default {
  name: "login",
  data() {
    return {
      isSubmit: false,
      data: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      sysMsg: "",
    };
  },
  mounted() {},
  methods: {
    // 提交登录表单--登录
    submitForm() {
      let self = this;
      this.$refs["data"].validate((valid) => {
        if (valid) {
          this.$http.post("/bridge/sys/user/login",this.data).then(res=>{
            if (res.code === 200) {
              let data = res.data;
              let baseUrl = data.bridgeList[0].shorthand;
              localStorage.setItem('bridge-token', data.token);
              localStorage.setItem('baseUrl', baseUrl);
              let bridgeList = [];
              for (let i = 0; i < data.bridgeList.length; i++) {
                bridgeList.push({
                  id: data.bridgeList[i].id,
                  name: data.bridgeList[i].name,
                  baseUrl: data.bridgeList[i].shorthand
                })
              }
              localStorage.setItem('userInfo',JSON.stringify(res.data));
              localStorage.setItem('bridgeList',JSON.stringify(bridgeList));
              self.__proto__.__proto__.$BRIDGE_NAME_LIST = bridgeList;
              self.__proto__.__proto__.$MQURL = "ws://"+window.location.host+"/mqsocket/" + baseUrl + "/";
              if (data.isProvince) {
                this.$router.replace("/platform/new");
              } else {
                // this.$router.replace(this.$DEFAULT_URL);
                this.getMenu(data.bridgeList[0].id)
              }
            } else {
              this.$message.error('用户名或密码错误');
            }
          })

        }
      });
    },
    getMenu(bridgeId){
      console.log("menu:"+bridgeId)
      this.$http.get('/bridge/sys/menu/tree/bri',{params:{id:bridgeId}}).then(res => {
        if (res.success){
          localStorage.setItem('menuList',JSON.stringify(res.data));
          this.$router.replace(this.$DEFAULT_URL);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取window.location参数
    getAllUrlParams() {
      // 获取完整的URL
      var fullUrl = window.location.href;

      // 使用正则表达式提取查询参数部分
      var match = fullUrl.match(/\?(.*)/);

      // 检查是否匹配到查询参数
      if (match) {
        var queryString = match[1];
        var params = queryString.split("&");
        var queryParams = {};

        for (var i = 0; i < params.length; i++) {
          var param = params[i].split("=");
          var key = decodeURIComponent(param[0]);
          var value = decodeURIComponent(param[1] || "");

          queryParams[key] = value;
        }

        return queryParams;
      } else {
        console.log('当前URL没有查询参数。');
        return {};
      }
    },
  },
};
</script>

